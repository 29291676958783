import React from "react"
import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
/*
=============== 
Variables
===============
*/
:root{
  // heading font sizes

  --font-size-desktop-h1: 3em;
  --font-size-desktop-h2: 1.45em;
  --font-size-desktop-h3: 1.15em;

  --font-size-tablet-h1: 1.45em;
  --font-size-tablet-h2: 1.1em;
  --font-size-tablet-h3: 1em;
  
  --font-size-mobile-h1: 1.45em;
  --font-size-mobile-h2: 1.34em;
  --font-size-mobile-h3: 1.15em;

  // regular font size
  --font-size: 1em;

  // colors
  --clr-green: #D3D600;
  --clr-blue: #002537;
  --clr-black: #000000;
  --clr-white: #FFFFFF;
  --clr-grey: #EEEEEE;
  --clr-green-hover: #BFC138;
  --clr-green-clicked: #989A21;

  // border
  --border: 3px solid var(--clr-green);

  // desktop gaps
  --gap-165: 120px;
  --gap-107: 107px;
  --gap-87: 87px;
  --gap-66: 66px;
  --gap-50: 40px;
  --gap-32: 32px;
  --gap-27: 27px;

  // tablet gaps
  --gap-83: 83px;
  --gap-45: 45px;
  --gap-38: 38px;
  --gap-18: 18px;
  --gap-13: 13px;

  // mobile gaps
  --gap-93: 93px;
  --gap-68: 68px;
  --gap-65: 65px;
  --gap-43: 43px;
  --gap-41: 41px;

  // media query widths
  --max-width-desktop: 1919px;
  --max-width-tablet: 800px;
  --max-width-mobile: 500px;

  // grid width
  --grid-maxWidth: 1640px;
  --grid-desktop: 1280px;
  --grid-tablet: 800px;
  --grid-mobile: 500px;

  // grid gaps
  --grid-padding-140: 140px;
  --grid-padding-138: 138px;
  --grid-padding-80: 80px;
  --grid-padding-36: 36px;
  
  --grid-width-122: 122px;
  --grid-width-78: 78px;
  --grid-width-69: 69px;
  --grid-width-62: 62px;

  --grid-gap-16: 16px;
  --grid-gap-10: 10px;

}

/*
=============== 
Global Styles
===============
*/

*{
  margin:0,;
  padding:0;
  box-sizing: border-box;
  font-family: "Acumin Pro";
}

h1,h2,h3,h4,h5{
font-weight: bold;
color: var(--clr-blue);
}

h3, h1{
  text-transform: uppercase;
}

body{
    overflow-x: hidden;
}

/*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
    all: unset;
    display: revert;
}

/* Preferred box-sizing value */
*,
*::before,
*::after {
    box-sizing: border-box;
}

*:focus {
  outline: auto;
}

/* Reapply the pointer cursor for anchor tags */
a, button {
    cursor: revert;
}

/* Remove list styles (bullets/numbers) */
ol, ul, menu {
    list-style: none;
}

/* For images to not be able to exceed their container */
img {
    max-width: 100%;
}

/* removes spacing between cells in tables */
table {
    border-collapse: collapse;
}

/* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
input, textarea {
    -webkit-user-select: auto;
}

/* revert the 'white-space' property for textarea elements on Safari */
textarea {
    white-space: revert;
}

/* minimum style to allow to style meter element */
meter {
    -webkit-appearance: revert;
    appearance: revert;
}

/* reset default text opacity of input placeholder */
::placeholder {
    color: unset;
}

/* fix the feature of 'hidden' attribute.
   display:revert; revert to element instead of attribute */
:where([hidden]) {
    display: none;
}

/* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
:where([contenteditable]:not([contenteditable="false"])) {
    -moz-user-modify: read-write;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    -webkit-line-break: after-white-space;
    -webkit-user-select: auto;
}

/* apply back the draggable feature - exist only in Chromium and Safari */
:where([draggable="true"]) {
    -webkit-user-drag: element;
}


`

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      {element}
    </>
  )
}
